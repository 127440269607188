<!-- 对象通用列表页 -->
<template>
  <!-- 开发人员：王雪茹 -->
  <div class="objectTableMain">
    <div class="object_box">
      <div class="price_box">
        <div class="tit">
          <svg class="icon viewImg" aria-hidden="true">
            <use href="#icon-hometab"></use>
          </svg>
          <div>
            <p style="font-size: 12px">
              {{ $t('lable.opppdt.selPbk.pricebook') }}
            </p>
            <!-- 全部 -->
            <p style="font-size: 18px; color: #080707">
              {{ $t('label.quickbooks.view.all') }}
            </p>
          </div>
        </div>
        <div class="search">
          <div style="width: 300px">
            <el-input
              v-model="searchKeyWord"
              :placeholder="$t('label.searchs')"
              style="margin-top: 2px"
              @change="changeKeyWord"
            ></el-input>
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-top: 2px"
              @click="changeKeyWord"
            >
              <use href="#icon-sousuo"></use>
            </svg>
          </div>
          <!-- 新建 -->
          <el-button
            type="primary createBtn pull-right"
            size="mini"
            style="padding: 7px 12px; margin-top: 1px; border: 0"
            @click="add"
            >{{ $t('label.ems.create') }}</el-button
          >
        </div>
      </div>
      <!-- 表格 -->
      <!--  -->
      <div class="table_s">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          class="price_table"
          :empty-text="$t('label.dashboard.noRecord')"
          v-loadmore="loadMore"
          :height="tableHeight"
          v-loading="priceLoading"
        >
          <!-- 价格手册名 -->
          <el-table-column
            prop="date"
            :label="$t('label.product.pricebooks.name')"
          >
            <template slot-scope="scope">
              <span class="allow-click" @click="detail(scope.row)">{{
                scope.row.name
              }}</span>
            </template>
          </el-table-column>
          <!-- 备注 -->
          <el-table-column
            prop="description"
            :label="$t('label.description')"
          ></el-table-column>
          <!-- 已启用 -->
          <el-table-column
            prop="address"
            :label="$t('label.inusing')"
            class="cent_s"
          >
            <template slot-scope="scope">
              <el-checkbox
                disabled
                :value="scope.row.isenable === 'true' ? true : false"
              ></el-checkbox>
            </template>
          </el-table-column>
          <!-- 上次修改时间 -->
          <el-table-column
            prop="createdate"
            :label="$t('label.trigger.lastmodifydate')"
          ></el-table-column>
          <el-table-column prop="address" label width="100" :align="'center'">
            <template slot-scope="scope">
              <el-popover
                placement="bottom-end"
                popper-class="pop_small"
                :visible-arrow="false"
                trigger="hover"
              >
                <svg
                  class="icon inline_block little_hand"
                  aria-hidden="true"
                  slot="reference"
                >
                  <use href="#icon-xiala"></use>
                </svg>
                <ul>
                  <!-- 编辑 -->
                  <li
                    v-show="scope.row.isCanEdit"
                    @click="
                      handleTableAction(
                        scope,
                        $t('pagecreator_page_button_edit')
                      )
                    "
                  >
                    <span>{{ $t('pagecreator_page_button_edit') }}</span>
                  </li>
                  <!-- 删除 -->
                  <li
                    v-show="scope.row.isCanDelete"
                    @click="
                      handleTableAction(
                        scope,
                        $t('component_setup_tabs_label_delete')
                      )
                    "
                  >
                    <span>{{ $t('component_setup_tabs_label_delete') }}</span>
                  </li>
                  <li @click="disableFun(scope)">
                    <!-- '禁用' -->
                    <span>{{
                      scope.row.isenable === 'true'
                        ? $t('label.forbidden')
                        : $t('label.licence.enable')
                    }}</span>
                  </li>
                  <!-- <li>
                  <span @click="handleTableAction(scope, '共享')">共享</span>
                </li> -->
                </ul>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 新增 编辑 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        width="670px"
        class="new_dialog"
      >
        <el-form
          :model="priceForm"
          :rules="rules"
          ref="priceForm"
          label-position="left"
        >
          <!-- 价格手册名 -->
          <el-form-item
            :label="$t('label.product.pricebooks.name')"
            :label-width="formLabelWidth"
            style="position: relative"
            prop="name"
          >
            <el-input
              v-model="priceForm.name"
              autocomplete="off"
              size="small"
              style="width: 250px"
            ></el-input>
            <div style="position: absolute; top: 0; right: 50px">
              <!-- 已启动 -->
              <span style="margin-right: 74px">{{ $t('label.inusing') }}</span>
              <el-checkbox v-model="isen"></el-checkbox>
            </div>
          </el-form-item>
          <!-- 备注 -->
          <el-form-item
            :label="$t('label.description')"
            :label-width="formLabelWidth"
            prop="description"
          >
            <el-input
              v-model="priceForm.description"
              autocomplete="off"
              size="small"
              style="width: 250px"
            ></el-input>
          </el-form-item>
          <!-- 编辑价格手册 -->
          <ul class="edit_ul" v-show="title === $t('vue_label_pricebook_edit')">
            <li>
              <!-- 创建人 -->
              <span>{{ $t('label.createby') }}</span>
              <span
                >{{ priceForm.lastmodifybyid }}，{{
                  priceForm.lastmodifydate
                }}</span
              >
            </li>
            <li>
              <!-- 上次修改人 -->
              <span>{{ $t('label.lasttimemodifier') }}</span>
              <span
                >{{ priceForm.createbyid }}，{{ priceForm.createdate }}</span
              >
            </li>
          </ul>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <!-- 取消 -->
          <el-button @click="resetForm('priceForm')" size="small">{{
            $t('button_partner_cancel')
          }}</el-button>
          <!-- 保存并新建 -->
          <el-button
            v-show="title !== $t('vue_label_pricebook_edit')"
            @click="saveFun('priceForm', 'newSave')"
            size="small"
            >{{ $t('label.saveandnew') }}</el-button
          >
          <!-- 保存 -->
          <el-button
            type="primary"
            @click="saveFun('priceForm', 'save')"
            size="small"
            >{{ $t('label.save') }}</el-button
          >
        </div>
      </el-dialog>
      <!-- 删除数据提示 -->
      <!-- 删除 -->
      <el-dialog
        :visible.sync="dialogVisible"
        :title="$t('label_chatter_delete')"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <!-- 确认删除该条数据吗 -->
        <span style="font-size: 14px; color: #080707"
          >{{ $t('label.weixin.confirm.delete') }}</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">{{
            $t('button_partner_cancel')
          }}</el-button>
          <el-button type="primary" @click="confirmDel" size="mini"
            ><!-- 删除 -->
            {{ $t('label.delete') }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  GetAllPriceBooks,
  SavePriceBook,
  EditPriceBook,
  DeletePriceBook,
  SetEnable,
  getPermissionById
} from './api.js'

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper')
        selectWrap.addEventListener('scroll', function() {
          let sign = 100
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= sign) {
            binding.value()
          }
        })
      },
    },
  },
  components: {},
  data() {
    return {
      tableHeight: '300px',
      dialogVisible: false,
      title: '',
      tableData: [],
      checkeds: false,
      dialogFormVisible: false,
      priceForm: {},
      formLabelWidth: '130px',
      rules: {
        //    '此项为必填项'
        name: [
          {
            required: true,
            message: this.$i18n.t('vue_label_lead_required_fields'),
            trigger: 'blur',
          },
        ],
      },
      priceId: '', //当前记录id
      isFlag: this.$i18n.t('label.forbidden'), //禁用
      searchKeyWord: '', //搜索
      isen: false,
      noDataTip: false,
      page: 1,
      pageSize: 20,
      row: {},
      priceLoading: false,
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.tableHeight =
        document.getElementsByClassName('home_content')[0].clientHeight - 178
    })
    this.getAllPriceBooks()
  },
  methods: {
    //滚动事件
    loadMore() {
      let that = this
      // let dom = document.querySelector('.el-table__body-wrapper')
      let dom = document.getElementsByClassName('el-table__body-wrapper')[
        document.getElementsByClassName('el-table__body-wrapper').length - 1
      ]
      dom.addEventListener('scroll', function() {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight
        if (scrollDistance <= 1) {
          //等于0证明已经到底，可以请求接口
          if (!this.noDataTip) {
            that.fnThrottle(that.append, 500, 1000)()
          }
        }
      })
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this
      var timer = this.timer
      var begin = new Date().getTime()
      return function() {
        var current = new Date().getTime()
        clearTimeout(timer)
        if (current - begin >= duration) {
          method()
          begin = current
        } else {
          that.timer = setTimeout(function() {
            method()
          }, delay)
        }
      }
    },
    //表格滚动懒加载
    append() {
      if (!this.noDataTip) {
        this.getAllPriceBooks()
      }
    },
    //获取价格手册列表数据
    getAllPriceBooks() {
      this.priceLoading = true
      let data = {
        searchKeyWord: this.searchKeyWord,
        page: this.page,
        pageSize: this.pageSize,
      }
      GetAllPriceBooks(data).then((res) => {
        if (res.data.pricebooklist.length === 0 && this.page === 1) {
          this.tableData = []
          this.page++
        } else if (this.tableData.length === 0 || this.page === 1) {
          this.tableData = res.data.pricebooklist
          this.page++
        } else {
          this.tableData = [...this.tableData, ...res.data.pricebooklist]
          this.page++
        }
        //是否提示无更多数据
        if (res.data.pricebooklist.length === 0) {
          this.noDataTip = true
        }
        this.priceLoading = false
        // this.tableData.forEach(val=>{
        //   if(val.isenable === 'true'){
        //
        //     this.isFlag = "禁用"
        //   }else if(val.isenable === 'false'){
        //
        //     this.isFlag = "启用"
        //   }
        // })
      })
    },
    // 表格操作按钮
    handleTableAction(scoped, n) {
      this.row = scoped.row
      if (n === this.$i18n.t('pagecreator_page_button_edit')) {
        getPermissionById({ id: scoped.row.id }).then((res) => {
          if (res.data.isEdit) {
            this.dialogFormVisible = true
            this.title = this.$i18n.t('vue_label_pricebook_edit') //"编辑价格手册"
            EditPriceBook({ id: scoped.row.id }).then((res) => {
              this.priceForm = res.data
              if (this.priceForm.isenable === 'true') {
                this.isen = true
              } else if (this.priceForm.isenable === 'false') {
                this.isen = false
              }
            })
          } else {
            this.$message.warning(this.$i18n.t('report_label_norole')) //"抱歉，您无权编辑该条数据，请联系系统管理员。"
          }
        })
      } else if (n === this.$i18n.t('component_setup_tabs_label_delete')) {
        this.priceId = scoped.row.id
        if (
          this.$store.state.userInfoObj.profileId === 'aaa000001' ||
          name == 'Attachement'
        ) {
          this.dialogVisible = true
        } else {
          getPermissionById({ id: this.priceId }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true
            } else {
              this.$message(
                this.$i18n.t('vue_label_commonobjects_detail_the_administrator')
              )
            }
          })
        }
      }
    },
    // 是否禁用
    disableFun(scoped) {
      let data = {
        id: scoped.row.id,
        isenable: scoped.row.isenable === 'true' ? 'false' : 'true',
      }
      SetEnable(data).then(() => {
        if (scoped.row.isenable === 'true') {
          scoped.row.isenable = 'false'
          this.$message.success(
            `${scoped.row.name}${this.$i18n.t('vue_label_pricebook_disabled')}`
          ) //价格手册已禁用
        } else {
          scoped.row.isenable = 'true'
          this.$message.success(
            `${scoped.row.name}${this.$i18n.t('vue_label_pricebook_activated')}`
          ) //价格手册已启用
        }
        this.getAllPriceBooks()
      })
    },
    // 确认删除数据
    confirmDel() {
      DeletePriceBook({
        id: this.priceId,
      }).then(() => {
        this.dialogVisible = false
        this.$message.success(
          `${this.row.name}${this.$i18n.t('vue_label_pricebook_deleted')}`
        ) //价格手册已删除
        this.getAllPriceBooks()
      })
    },
    //新建价格手册
    add() {
      this.title = this.$i18n.t('lable.product.pricebooks.new')
      this.dialogFormVisible = true
      this.priceForm = {}
    },
    //搜索
    changeKeyWord() {
      this.getAllPriceBooks()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    // 详情
    detail(row) {
      this.$router.push({
        path: `/priceManual/price-detail/${row.id}`,
      })
    },
    //新增保存
    saveFun(formName, isSave) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isen) {
            this.priceForm.isenable = 'true'
          } else {
            this.priceForm.isenable = 'false'
          }
          SavePriceBook(this.priceForm).then((res) => {
            if (res.result) {
              if (isSave === 'newSave') {
                // 保存成功
                this.$message.success(this.$i18n.t('label.search.saveok'))
                this.dialogFormVisible = false
                this.priceForm = {}
                this.dialogFormVisible = true
                this.getAllPriceBooks()
              } else {
                this.$router.push({
                  path: `/priceManual/price-detail/${res.data.id}`,
                })
              }
            } else {
              this.$message.error(this.$i18n.t('savefail'))
            }
          })
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.dialogFormVisible = false
      this.priceForm = {}
    },
  },
}
</script>

<style lang="scss" scoped>
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #f5f5f5;

    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #dedcda;
      padding: 20px;
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #dedcda;
      padding: 20px;
    }
    ::v-deep .el-form-item__label {
      font-size: 14px;
    }
    .new_dialog {
      ::v-deep .el-form-item__label:before {
        margin-left: -10px;
      }
      .el-form-item {
        margin-bottom: 12px;
        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #005fb2;
          border-color: #005fb2;
        }
      }
      .edit_ul {
        li {
          margin-bottom: 23px;
          span:first-child {
            width: 130px;
            display: inline-block;
          }
          font-size: 14px;
        }
      }
    }
    .price_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background: #f3f2f2;
      .tit {
        line-height: 1.2;
        div {
          width: 150px;
          margin-top: 3px;
          p:first-child {
            font-size: 12px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #080707;
            font-weight: 600;
          }
        }
      }
      .search {
        float: right;
        display: flex;
        min-width: 370px;
        margin-top: 3px;
        div {
          position: relative;
          svg {
            width: 16px;
            position: absolute;
            top: 7px;
            left: 10px;
          }
          ::v-deep .el-input__inner {
            height: 30px;
            padding-left: 32px;
            border: 1px solid #dedcda;
            font-size: 12px;
          }
        }
      }
      div {
        height: 37px;
        .viewImg {
          margin-right: 10px;
          margin-left: 10px;
          width: 37px;
          height: 37px;
          float: left;
        }
        p:first-child {
          width: 100%;
          margin-top: -2px;
          margin-bottom: 0;
        }
        p:nth-child(2) {
          width: 100%;
          margin: 0;
          font-size: 16px;
        }
      }
      .createBtn {
        height: 30px;
        margin-right: 10px;
        margin-left: 15px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        padding-left: 40px;
        border: 1px solid #c8c6c6;
      }
    }
    .table_s {
      .price_table {
        ::v-deep .is-leaf {
          background: #fafafa;
          color: #333333;
          border-top: 1px solid #dedcda;
          border-right: 1px solid #dedcda;
          padding: 8px 0;
          .el-checkbox {
            margin-left: 4px;
          }
        }
        ::v-deep td {
          padding: 9px 0;
          background: #fff;
        }
        ::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
          background: #005fb2;
          border-color: #005fb2;
        }
        .el-table__body-wrapper {
          overflow-y: auto;
          // max-height: 150px;
          // 提示语
          .noDataTip {
            height: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            background: #fff;
          }
        }
      }
    }

    .allow-click {
      color: #006dcc;
      cursor: pointer;
    }
  }
  ::v-deep .el-button--primary:focus,
  .el-button--primary:hover {
    background: #005fb2;
    color: #fff;
    border-color: #005fb2;
  }
}
</style>
<style scoped>
.pop_small{
  width:auto!important;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding: 0 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  cursor: pointer;
  color: #006dcc;
}
</style>
